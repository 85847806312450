<script>
import { computed } from '@vue/composition-api'
import store from '@/infrastructure/store'
import FriendCard from '../components/FriendCard'
import useRealtimeSubscriptions from '@/infrastructure/realtime/realtimeSubscriptions'
import useRealtimeGroups from '@/infrastructure/realtime/realtimeGroups'
import FriendItemsList from '@/features/items/components/FriendItemsList'
import AddItem from '@/features/items/components/AddItem'
import ItemSortAndFilterCard from '@/features/items/components/ItemSortAndFilterCard'
import * as itemFilters from '@/features/items/services/itemFilters'
import * as itemSorters from '@/features/items/services/itemSorters'
import {
  useItemFiltering,
  useItemSearching,
  useItemSorting,
  useQuerySyncForItemSearching,
  useQuerySyncForItemFiltering,
  useQuerySyncForItemSorting,
} from '@/features/items/composables/itemComposables'

export default {
  components: {
    AddItem,
    FriendItemsList,
    FriendCard,
    ItemSortAndFilterCard,
  },

  props: {
    userId: { type: String, required: true },
  },

  setup(props) {
    const { subscribeToGroup } = useRealtimeGroups()
    subscribeToGroup('FriendItems', [props.userId])

    const onFriendItemUpdated = (itemId) => store.dispatch('items/fetchFriendItem', { itemId: itemId })

    const { subscribeToRealtimeEvent } = useRealtimeSubscriptions()
    subscribeToRealtimeEvent('FriendItem:Claimed', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Unclaimed', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:ContributionAdded', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:ContributionRemoved', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Archived', onFriendItemUpdated)
    subscribeToRealtimeEvent('FriendItem:Unarchived', onFriendItemUpdated)

    const authUserId = computed(() => store.getters['auth/authUserId'])

    const allItems = computed(() => store.getters['items/getFriendUnarchivedItems'](props.userId))

    const filterOptions = [
      itemFilters.notClaimedByOthersFilter,
      itemFilters.notClaimedByMeFilter,
      itemFilters.hasPriceFilter,
      itemFilters.hasRatingFilter,
      itemFilters.hasShopFilter,
      itemFilters.isSurpriseItemFilter,
    ]

    const sortOptions = [
      itemSorters.createByRatingSorter({ descending: true }),
      itemSorters.createByRatingSorter(),
      itemSorters.createByPriceSorter(),
      itemSorters.createByPriceSorter({ descending: true }),
      itemSorters.createByCreatedDateSorter({ descending: true }),
      itemSorters.createByCreatedDateSorter(),
      itemSorters.createByNameSorter(),
      itemSorters.createByNameSorter({ descending: true }),
    ]

    const { searchText, itemsMatchingSearchQuery } = useItemSearching({ items: allItems })
    const { selectedFilterOptions, filteredItems } = useItemFiltering({
      items: itemsMatchingSearchQuery,
      authUserIdRef: authUserId,
    })
    const { selectedSortOption, sortedItems } = useItemSorting({ items: filteredItems })

    const defaultSortOption = sortOptions[0]
    selectedSortOption.value = defaultSortOption

    const { onUserUpdatedSearchText } = useQuerySyncForItemSearching({
      searchTextRef: searchText,
    })

    const { onUserUpdatedSelectedFilterOptions } = useQuerySyncForItemFiltering({
      filterOptions: filterOptions,
      selectedFilterOptionsRef: selectedFilterOptions,
    })

    const { onUserUpdatedSelectedSortOption } = useQuerySyncForItemSorting({
      sortOptions: sortOptions,
      selectedSortOptionRef: selectedSortOption,
      defaultSortOption: defaultSortOption,
    })

    return {
      filterOptions,
      sortOptions,

      onUserUpdatedSearchText,
      onUserUpdatedSelectedFilterOptions,
      onUserUpdatedSelectedSortOption,
      searchText,
      selectedFilterOptions,
      selectedSortOption,

      allItems: allItems,
      displayedItems: sortedItems,
    }
  },

  data() {
    return {
      isLoadingFriend: false,
      isLoadingItems: false,
      animateAddItemElement: false,
      highlightedItemScrollOffset: 80,
      highlightedItemId: undefined,
      showFriendCardActions: false,
    }
  },

  computed: {
    authUserId: () => store.getters['auth/authUserId'],
    authUserIsAdmin: () => store.getters['auth/authUserIsAdmin'],
    friend: (ctx) => store.getters['friends/getFriendByUserId'](ctx.userId),

    hasAnyItems() {
      return this.allItems.length > 0
    },

    friendCardListeners() {
      if (this.showFriendCardActions) {
        return {}
      } else {
        return { click: () => (this.showFriendCardActions = !this.showFriendCardActions) }
      }
    },
  },

  watch: {
    friend(to, from) {
      if (to) {
        store.commit('setPageTitle', `${to.friendFullName} items`)
      } else if (from && !to) {
        // this should be when the friend request creator decided to "Unfriend" so the whole request is deleted
        this.$router.push({ name: 'friends' })
        store.commit('addToast', { message: 'Unfriended', color: 'success' })
      }
    },
    animateAddItemElement: {
      handler(to) {
        if (to) {
          setTimeout(() => {
            this.$nextTick(() => {
              this.$vuetify.goTo(this.$refs.addItemElement, {
                appOffset: true,
                offset: 0,
              })

              setTimeout(() => {
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.animateAddItemElement = false
                  }, 2000)
                })
              }, 500)
            })
          }, 50)
        }
      },
      immediate: true,
    },
  },

  mounted() {
    if (this.$route.query.highlightedItemId) {
      this.highlightedItemId = this.$route.query.highlightedItemId
    }

    this.loadFriend()
    this.loadItems()
  },

  methods: {
    async loadFriend() {
      this.isLoadingFriend = true
      try {
        await store.dispatch('friends/fetchFriendRequest', { friendUserId: this.userId })

        store.commit('friends/addRecentFriend', {
          userId: this.friend.friendUserId,
          fullName: this.friend.friendFullName,
          picture: this.friend.friendPicture,
        })
      } finally {
        this.isLoadingFriend = false
      }
    },

    async loadItems() {
      this.isLoadingItems = true
      try {
        await store.dispatch('items/fetchFriendItems', { userId: this.userId })
      } finally {
        this.isLoadingItems = false
      }
    },

    onFriendshipRejected() {
      this.$router.push({ name: 'friends' })
      store.commit('addToast', { message: 'Friend request rejected', color: 'success' })
    },

    impersonateUser(friend) {
      store.commit('setUserToImpersonate', { userId: this.userId, fullName: friend.friendFullName })
      this.$router.push({ name: 'home' })
    },
  },
}
</script>

<template>
  <div>
    <v-sheet v-if="!friend" class="pa-3">
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </v-sheet>

    <FriendCard
      v-else
      :loading="isLoadingFriend || isLoadingItems"
      :user-id="friend.friendUserId"
      :full-name="friend.friendFullName"
      :email="friend.friendEmail"
      :picture="friend.friendPicture"
      :status="friend.status"
      :show-action-buttons="showFriendCardActions"
      hide-short-description
      v-on="friendCardListeners"
      @rejected="onFriendshipRejected"
    >
      <template v-slot:footer>
        <div v-if="showFriendCardActions">
          <div v-if="authUserIsAdmin">
            <v-divider />

            <v-card-text>
              <div class="text-center">
                <v-btn text color="secondary" @click.stop="impersonateUser(friend)">Impersonate</v-btn>
              </div>
            </v-card-text>

            <v-divider />
          </div>

          <v-card-text>
            <div class="text-center">
              <v-btn text color="secondary" @click.stop="showFriendCardActions = false">Close</v-btn>
            </div>
          </v-card-text>
        </div>
      </template>
    </FriendCard>

    <template v-if="hasAnyItems">
      <div class="pt-3"></div>

      <ItemSortAndFilterCard
        :search-text.sync="searchText"
        :filter-options="filterOptions"
        :selected-filter-options.sync="selectedFilterOptions"
        :sort-options="sortOptions"
        :selected-sort-option.sync="selectedSortOption"
        @update:searchText="onUserUpdatedSearchText"
        @update:selectedFilterOptions="onUserUpdatedSelectedFilterOptions"
        @update:selectedSortOption="onUserUpdatedSelectedSortOption"
      >
        <template v-slot:extra-buttons>
          <v-btn icon @click="animateAddItemElement = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
      </ItemSortAndFilterCard>
    </template>

    <div class="pt-3"></div>

    <template v-if="friend && allItems">
      <FriendItemsList
        :auth-user-id="authUserId"
        :friend-user-id="userId"
        :items="displayedItems"
        :highlighted-item-id="highlightedItemId"
        :highlighted-item-scroll-offset="highlightedItemScrollOffset"
        item-css-class="mb-3"
      />

      <AddItem :friend-user-id="userId" :friend-user-name="friend.friendFullName" ref="addItemElement" :class="{ shake: animateAddItemElement }" />

      <div class="pt-3"></div>

      <v-card>
        <v-card-text>
          <v-btn block large text :to="{ name: 'friend-with-archived-items', params: { userId: this.userId } }">Show archived items </v-btn>
        </v-card-text>
      </v-card>

      <div class="pt-3"></div>

      <v-card>
        <v-card-text>
          <v-btn block large text :to="{ name: 'friend-with-claimed-items', params: { userId: this.userId } }">Show claimed items </v-btn>
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>
